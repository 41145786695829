/*-------------------------------------

Dear Lane,

Would you be in my wedding party?? I'd be privileged to have you standing next to me !important 

Of course! 

-------------------------------------*/

/*-------------------------------------

 IMPORTANT: keeps the nav bar at 100% width
 always use this

-------------------------------------*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

@import 'modal';
@import 'plane';

@font-face {
  font-family: 'Kepler';
  src: url('../img/fonts/KeplerStd-MediumDisp.otf');
}

$break-small: 605px;
$break-large: 1400px;

body {
  background: rgba(230, 231, 232, 1);
  position: relative;
  overflow-x: hidden;
  width: 100%;
  animation: fadeIn 0.9s ease;
  height: 9530px;
  @media (max-width: $break-large) {
    height: 7200px;
  }
  @media (max-width: 1040px) {
    height: 6900px;
  }
  @media (max-width: 8050px) {
    height: 6600px;
  }
  @media (max-width: $break-small) {
    height: 6990px;
  }
}

.inner-body {
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 5;
}

/*-------------------------------------

main nav bar

-------------------------------------*/
header {
  color: black;
  position: fixed;
  z-index: 1;
  font-family: 'Kepler', 'Roboto', serif;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 50px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 30px 20px 40px;
  a {
    color: inherit;
    text-decoration: none;
  }
  @media (max-width: $break-small) {
    border-bottom: none;
  }
}

@media (max-width: $break-small) {
  nav {
    display: flex;
    padding: 10px 4px;
    font-size: 0px;

    text-align: center;
  }
}

.js-header-offset {
  font-size: 16px;
  /* shrinks the nav and text down when scrolling - see app.js */
  @media (max-width: $break-small) {
    font-size: 9px;
    padding: 0;
  }
}

@media (max-width: $break-small) {
  .js-header-offset nav {
    display: flex;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding-top: 16px;
    transition-duration: 0.2s;
    background: rgba(230, 231, 232, 0.92);
  }
}

#kevin-kara-title {
  float: left;
}

.js-header-offset #kevin-kara-title {
  @media (max-width: $break-small) {
    // display: none;
    opacity: 0;
  }
}

.innernav {
  float: right;
}

.innernav a {
  padding: 0 5px 5px 5px;
  margin: 0 7px;
  display: inline-block;
  border-bottom: 1px solid transparent;
  &:hover {
    /* font-weight: bold; */
    border-bottom: 1px solid #000;
    color: black;
  }
  @media (max-width: $break-small) {
    padding: 0 0px 8px 0px;
  }
}

/*-------------------------------------

main elements

-----------------;--------------------*/

/* foreground (balloons/landscape)*/
/*div#parallax-bg-1{
	position: fixed;
	width: 1200px;
	top: 0;
	left: 50%; 
	margin-left: -600px; 
}*/

/* background middle layer*/
div#parallax-bg-2 {
  position: fixed;
  width: 100%;
  top: 0;
  left: 50%;
  margin-left: -600px;
}
/* background layer */
div#parallax-bg-3 {
  position: fixed;
  width: 100%;
}
/* foreground */
div#parallax-bg-3 div {
  background-repeat: no-repeat;
  position: absolute;
}

div#bg-3-1 {
  background-image: url('/img/hero.png');
  background-size: 70%;
  width: 92%;
  height: 1200px;
  top: 60px;
  margin-left: 15%;
  @media (max-width: $break-small) {
    background-size: 100%;
    width: 100%;
    margin: 0 auto;
  }
}

div#bg-3-2-itinerary {
  background-image: url('/img/itinerary-v6.png');
  background-size: 80%;
  width: 75%;
  position: absolute;
  padding-top: 112%;

  top: 1400px;
  // border: solid 1px red;
  margin-left: -2%;
  max-width: 1600px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: $break-small) {
    background-size: 100%;
    width: 141%;
    top: 750px;
    margin-left: -6%;
    padding-top: 135%;
  }
}

div#bg-3-3-accommodations {
  background-image: url('/img/travel-Accomodations-text4.png');
  background-size: 90%;
  width: 93%;
  top: 2600px;

  z-index: 2;
  position: absolute;
  padding-top: 89%;
  // border: solid 1px red;
  margin-left: 15%;
  max-width: 1600px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  @media (min-width: $break-large) {
    top: 3000px;
  }
  @media (max-width: $break-small) {
    background-image: url('/img/travel-Accomodations-mobile-text4.png');
    background-size: 100%;
    padding-top: 260%;
    width: 100%;
    top: 1600px;
  }
}

div#bg-3-4-registry {
  background-image: url('/img/text/registry-text2.png');
  background-size: 90%;
  width: 100%;
  position: absolute;
  padding-top: 50%;
  z-index: 3000;
  // border: solid 1px red;
  top: 3600px;
  max-width: 1600px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  @media (min-width: $break-large) {
    top: 4500px;
  }
  @media (max-width: $break-small) {
    background-image: url('/img/text/kk-Registry-mobile-text3.png');
    background-size: 100%;
    width: 100%;
    top: 3100px;
    padding-top: 130%;
    margin: 0 auto;
  }
}

#bg-footer {
  background-image: url('/img/text/Footer-text2.png');
  background-size: 100%;
  position: absolute;
  bottom: -10px;
  background-position: bottom center;
  background-repeat: no-repeat;
  left: 0;
  width: 100%;
  right: 0;
  display: block;
  width: 100%;

  padding-top: 90%;
  @media (max-width: $break-small) {
    // top: 4100px;
    background-image: url('/img/text/footer-mobile-text7.png');
    background-size: 100%;
  }
}
/* middle layer clouds */
div#parallax-bg-2 div {
  background-image: url('/img/large-cloud.png');
  /*	background-size:80%;*/
  position: absolute;
  display: block;
  width: 186px;
  height: 80px;
  // animation: float 6s ease-in-out infinite;
  /*	overflow:hidden;*/
}

div#bg-2-1 {
  top: 100px;
  left: -310px;
}

div#bg-2-2 {
  top: 270px;
  right: -70px;
}

div#bg-2-3 {
  top: 870px;
  left: -300px;
}

div#bg-2-4 {
  top: 1120px;
  right: -130px;
}

div#bg-2-5 {
  top: 1620px;
  left: 140px;
}

div#bg-2-6 {
  top: 720px;
  left: 340px;
}

div#bg-2-8 {
  top: 2400px;
  left: 20%;
}

div#bg-things-to-do {
  background-image: url('/img/things-to-do-in-the-area-desktop.png');
  background-size: 100%;
  position: absolute;
  background-position: top center;
  background-repeat: no-repeat;
  left: 4%;
  top: 4100px;
  right: 0;
  display: block;
  width: 93%;

  padding-top: 80%;
  @media (min-width: $break-large) {
    top: 5400px;
  }
  @media (max-width: $break-small) {
    padding-top: 320%;
    width: 100%;
    top: 3870px;
    left: 0;
    background-image: url('/img/things-to-do-in-the-area-mobile.png');
    background-size: 100%;
  }
}

/*background layer clouds */
div#parallax-bg-1 div {
  background-repeat: no-repeat;
  position: absolute;
  display: block;
  width: 488px;
  height: 138px;
  /*	overflow:hidden;*/
}

/*div#bg-1-3-cloud{
	background-image:url('/img/cloud-1-small.png');
	width: 133px;
	height: 86px;
	top: 850px;
	right:-290px;
}*/
div#bg-1-4-cloud {
  background-image: url('/img/small-cloud.png');
  width: 133px;
  height: 86px;
  top: 1200px;
  left: -200px;
  // animation: float2 6s ease-in-out infinite;
}

div#bg-1-5-cloud {
  background-image: url('/img/small-cloud-2.png');
  width: 133px;
  height: 86px;
  top: 2050px;
  left: 80%;
  // animation: float 6s ease-in-out infinite 2s;
}

div#bg-1-6-cloud {
  background-image: url('/img/small-cloud-2.png');
  width: 133px;
  height: 86px;
  top: 3550px;
  left: -1%;
  @media (max-width: $break-small) {
    // display: none !important ;
  }
  // animation: float2 6s ease-in-out infinite 3s;
}

// div#bg-1-7 {
//   /* background-image: url('/img/large-cloud-2.png'); */
//   /*	background-size:60%;*/
//   width: 133px;
//   height: 100px;
//   top: 4550px;
//   left: 55%;
// }

div#bg-1-8 {
  background-image: url('/img/small-cloud.png');
  width: 133px;
  height: 86px;
  top: 4450px;
  left: 45%;
  @media (max-width: $break-small) {
    top: 4050px;
  }
  // animation: float 6s ease-in-out infinite 1s;
}

footer {
  background: none;
  color: #505050;
  bottom: 0;
  right: 0;
  left: 0;
  padding-bottom: 10px;
  position: absolute;
  text-align: center;
  z-index: 4;
  font-size: 11px;
}

// registry buttons
.registry-container {
}

.registry-buttons {
  position: absolute;
  // border: 1px solid red;
  text-align: center;
  top: 23%;
  width: 300px;

  left: 10%;
  left: 9vw;

  @media (max-width: 1560px) {
    top: 25%;
  }
  @media (max-width: 1200px) {
    width: 237px;
    top: 25%;
    left: 11%;
    left: 9vw;
  }

  @media (max-width: 1000px) {
    top: 22%;
    left: 9%;
    left: 7vw;
  }

  @media (max-width: 860px) {
    width: 203px;
  }

  @media (max-width: 760px) {
    top: 22%;
    left: 10%;
    left: 8vw;
    width: 180px;
  }

  @media (max-width: 700px) {
    top: 21%;
    left: 9%;
    left: 8vw;
    width: 180px;
  }
  @media (max-width: $break-small) {
    top: 19%;
    left: 21%;
    width: 300px;
  }

  @media (max-width: 550px) {
    top: 20%;
    left: 21%;
    width: 280px;
  }
  @media (max-width: 550px) {
    left: 23%;
    width: 240px;
  }

  @media (max-width: 455px) {
    left: 22%;
    width: 220px;
    top: 18%;
  }

  @media (max-width: 415px) {
    left: 22%;
    width: 181px;
    width: 49vw;
    top: 18%;
  }
}

#bg-3-4-registry {
  z-index: 3;
}

#bg-3-4-registry div.registry-button-wrap {
  position: relative;
  margin-bottom: 2px;
  a {
    border: 1px solid #383839;
    display: flex;
    transition: all 0.3s ease-in-out;
    margin: 0 auto 16px;
    width: 100%;
    max-width: 100%;
    text-decoration: none;
    min-height: 58px;
    color: black;
    vertical-align: middle;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    border-radius: 11px;
    line-height: 1.2;

    font-size: 24px;
    @media (max-width: 1200px) {
      line-height: 1.1;
      min-height: 50px;
      font-size: 22px;
      margin-bottom: 13px;
    }
    @media (max-width: 860px) {
      min-height: 41px;
      font-size: 20px;
      margin-bottom: 11px;
    }

    @media (max-width: 780px) {
      min-height: 38px;
    }

    @media (max-width: $break-small) {
      min-height: 44px;
      font-size: 21px;
    }

    @media (max-width: 500px) {
      min-height: 38px;
      font-size: 20px;
    }

    @media (max-width: 395px) {
      min-height: 34px;
      font-size: 18px;
    }
    @media (max-width: 395px) {
      margin-bottom: 6px;
    }

    @media (max-width: 281px) {
      margin-bottom: 5px;
      min-height: 31px;
      font-size: 17px;
    }

    span {
      display: block;
      font-size: 16px;
      @media (max-width: 1200px) {
        font-size: 14px;
      }
      @media (max-width: 1000px) {
        font-size: 12px;
      }
      // font-style: italic;
    }
    &:hover {
      border: 1px solid #383839;
      background-color: #fff;
    }
    &:active {
      border: 1px solid #000;
      background-color: #fff;
    }
  }
}
#bg-3-4-registry div.registry-button-wrap.last a {
  margin-bottom: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes float2 {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-17px);
  }
  100% {
    transform: translateY(0px);
  }
}
