.modal-overlay {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 200ms ease-in-out;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.modal-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal {
  position: relative;
  z-index: 1;
  background: white;
  transition: all 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  transform: translateY(100px);

  width: 90%;
  max-width: 600px;
  margin: 10px auto;
  padding: 10px;
  background-color: black;
  color: #fff;
  border: solid 1px white;
  outline: 12px solid black;
}

.modal-close {
  width: 65px;
  height: 65px;
  cursor: pointer;
  position: absolute;

  right: -10px;
  top: -10px;
  z-index: 4;
  .modal-close-inner {
    top: 22px;
    right: 22px;
    position: absolute;
    width: 22px;
    height: 22px;
    display: block;
    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      top: 50%;
      left: 0;
      margin-top: -1px;
      background: #fff;
      opacity: 1;
      // transition: opacity 0.3s ease;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  overflow: hidden;
  // transition: none;
  &:hover .modal-close-inner {
    &:before,
    &:after {
      opacity: 0.8;
    }
  }
}

.modal-overlay.is-active {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.modal-overlay.is-active .modal {
  opacity: 1;
  transform: translate(0);
}

.modal-button {
  width: 100%;
  text-align: center;
  color: white;
  text-decoration: none;
  display: block;
  line-height: 1;
  font-size: 30px;
  padding-top: 30px;
  transition: all 200ms ease-in-out;
  padding-bottom: 30px;
  &:hover {
    opacity: 0.8;
  }
}
.modal h1 {
  margin-top: 0;
}

@media only screen and (max-width: 720px) {
  .modal {
    padding: 30px;
  }

  .modal h1 {
    font-size: 22px;
    margin-bottom: 5px;
    display: inline-block;
  }
}
