.plane-wrap {
  position: fixed;
  z-index: 10000;
  top: 50%;
  pointer-events: none;
  transform: translateY(-50%) translateX(-100%);
  .js-rsvp & {
    animation: flyin 3.45s ease-in;
  }
}

body.js-rsvp {
  pointer-events: none;
}

.js-rsvp .inner-body {
  transition: all 1s ease-in;
  opacity: 0;
}

@keyframes flyin {
  0% {
    transform: translateY(-50%) translateX(-100%) scale(1);
  }

  100% {
    transform: translateY(-60%) translateX(300%) scale(1.7);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
